import '../../js/vendors/fancybox/jquery.fancybox';

const overviewBlogCC = 'c-posts-overview';

function searchPostsOnAction( event, self ) {
    event.preventDefault();

    let searchedKeyword = $.trim( self.val() );
    let postType = self.data( 'pt' );
    let postSlug = self.data( 'ps' );
    let ctaText = self.data( 'ct' );
    let notFoundText = self.data( 'nft' );

    $( `.${overviewBlogCC}__search-icon-wrapper` ).addClass( `${overviewBlogCC}__search-icon-wrapper--pulse` );
    $( `.${overviewBlogCC}__container` ).addClass( `${overviewBlogCC}__container--search-in-progress` );

    $.post(
        ajax_object.ajax_url,
        {
            action: 'do_posts_custom_search',
            post_type: postType,
            post_slug: postSlug,
            cta_text: ctaText,
            searched_keyword: searchedKeyword
        },
        function ( result ) {
            if ( result == "No Result" ) {
                result = `<div class="${overviewBlogCC}__no-result">${notFoundText}</div>`;
            }

            $( `.${overviewBlogCC}__search-icon-wrapper` ).removeClass( `${overviewBlogCC}__search-icon-wrapper--pulse` );
            $( `.${overviewBlogCC}__container` ).removeClass( `${overviewBlogCC}__container--search-in-progress` );

            $( `.${overviewBlogCC}__container` ).html( result );

            if ( result != "No Result" ) {
                $(`.${overviewBlogCC}__found-results-info`).addClass(`${overviewBlogCC}__found-results-info--show`);
            }
        }
    ).done( function () {
        if ( postType == "video" ) {
            $( '[data-fancybox-trigger="fancybox-video-blog"]' ).fancybox( {
                baseClass: "fancybox-video-blog",
                hash: false,
                arrows: false
            } );
        }
    } );
}

function showOverviewBlog () {
    let userAgentDetection = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    let mobileSearchEnterBtn = $( `.${overviewBlogCC}__search-mobile-btn` );

    if ( $( `.${overviewBlogCC}__search-input` ).val() != "" ) {
        $( `.${overviewBlogCC}__search-input` ).val( "" );
    }

    $( `.${overviewBlogCC}__container` ).on( 'click', `.${overviewBlogCC}__posts-container--three-columns-video .${overviewBlogCC}__posts-item-link`, function ( e ) {
        e.preventDefault();

        $( this ).parent().siblings( `.${overviewBlogCC}__posts-item-video-wrapper` ).children( `.${overviewBlogCC}__posts-item-video-link` ).trigger( 'click' );
    } );

    $( '[data-fancybox-trigger="fancybox-video-blog"]' ).fancybox( {
        baseClass: "fancybox-video-blog",
        hash: false,
        arrows: false
    } );

    if ( userAgentDetection ) {
        $( `.${overviewBlogCC}__search-input` ).on( 'keyup', function( e ) {
            if ( !mobileSearchEnterBtn.hasClass( `${overviewBlogCC}__search-mobile-btn--active` ) ) {
                mobileSearchEnterBtn.addClass( `${overviewBlogCC}__search-mobile-btn--active` );
            }

            if ( $( this ).val().length === 0 ) {
                mobileSearchEnterBtn.removeClass( `${overviewBlogCC}__search-mobile-btn--active` );
            }
        } );
    }

    mobileSearchEnterBtn.on( 'click', function ( e ) {
        let dataSearchInput = $( this ).siblings( `.${overviewBlogCC}__search-wrapper` ).children( `.${overviewBlogCC}__search-input` );

        searchPostsOnAction( e, dataSearchInput );
    } );

    $( `.${overviewBlogCC}__search-input` ).keypress( function ( e ) {
        if ( e.which == 13 && $( this ).val() != "" ) {
            searchPostsOnAction( e, $( this ) );
        }
    } );

    $( `.${overviewBlogCC}__container` ).on( 'click', ".c-pagination--dynamic .c-pagination__action-link", function ( e ) {
        e.preventDefault();

        let searchedKeyword = $( this ).data( 'sm' );
        let postType = $( this ).data( 'pt' );
        let postSlug = $( this ).data( 'ps' );
        let ctaText = $( this ).data( 'ct' );
        let customPaged = $( this ).data( 'paged' );

        $( `.${overviewBlogCC}__search-icon-wrapper` ).addClass( `${overviewBlogCC}__search-icon-wrapper--pulse` );
        $( `.${overviewBlogCC}__container` ).addClass( `${overviewBlogCC}__container--search-in-progress` );

        $.post(
            ajax_object.ajax_url,
            {
                action: 'do_posts_custom_search',
                post_type: postType,
                post_slug: postSlug,
                searched_keyword: searchedKeyword,
                cta_text: ctaText,
                custom_paged: customPaged
            },
            function ( result ) {
                $( `.${overviewBlogCC}__search-icon-wrapper` ).removeClass( `${overviewBlogCC}__search-icon-wrapper--pulse` );
                $( `.${overviewBlogCC}__container` ).removeClass( `${overviewBlogCC}__container--search-in-progress` );

                $( `.${overviewBlogCC}__container` ).html( result );

                $( `.${overviewBlogCC}__found-results-info` ).addClass( `${overviewBlogCC}__found-results-info--show` );
            }
        ).done( function () {
            if ( postType == "video" ) {
                $( '[data-fancybox-trigger="fancybox-video-blog"]' ).fancybox( {
                    baseClass: "fancybox-video-blog",
                    hash: false,
                    arrows: false
                } );
            }
        } );
    } );
}

export default showOverviewBlog;