const storyCardsCC = "c-story-cards";

function showStoryCards() {
    $( '[data-fancybox^="story-card-contact-form-modal"]' ).fancybox( {
        baseClass: "fancybox-contact-form-modal",
        infobar: false,
        hash: false
    } );

    $( `.${storyCardsCC}__item-img-link--modal` ).on( "click", function ( e ) {
        e.preventDefault();

        $( this ).parent().siblings( `.${storyCardsCC}__item-info` ).children( `.${storyCardsCC}__item-info-container` ).children( `.${storyCardsCC}__item-btn` ).trigger( "click" );
    } );
}

export default showStoryCards;