/**
 * NOTE: You should not need to modify
 * this file!
 *
 * These modules are used for almost all DOD projects.
 *
 */

/** Responsive Images Polyfill */
import 'picturefill';

/** Lazyload Images */
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset.js';

// Setup lazysizes widths for bg images:
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.customMedia = {
    '--small'  : '(max-width: 544px)',
    '--medium' : '(max-width: 992px)',
    '--large'  : '(max-width: 99999px)',
};
//

// Reveal CSS animation as a user scrolls down a page: https://github.com/matthieua/WOW
import WOW from 'wow.js/dist/wow.js';
new WOW().init();
