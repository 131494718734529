/* eslint-disable */

if (typeof Object.assign != 'function') {
    Object.assign = function(target) {
        'use strict';
        if (target == null) {
            throw new TypeError('Cannot convert undefined or null to object');
        }

        target = Object(target);
        for (var index = 1; index < arguments.length; index++) {
            var source = arguments[index];
            if (source != null) {
                for (var key in source) {
                    if (Object.prototype.hasOwnProperty.call(source, key)) {
                        target[key] = source[key];
                    }
                }
            }
        }
        return target;
    };
}

import './util/cookieFromUrl';
import './util/modernizr';
import './util/objectFillPolyfill';
import './vendors/iframeResize.min';

import jQuery from 'jquery';
import '../style.scss';

import scrollToTop from '../components/objects/scroll-to-top/scroll-to-top';
import searchBar from '../components/objects/search/search-bar/search-bar';
import navMob from '../components/objects/nav/nav-mobile/nav-mobile';
import accordionTypeOne from '../components/objects/accordions/accordion-type-1/accordion';
import accordionTypeTwo from '../components/objects/accordions/accordion-type-2/accordion'
// import showPhotoCollageGallery from '../components/blocks/photo-collage/photo-collage';
// import showAlternativeGrid from '../components/blocks/grid-alternative/grid-alternative';
import socialShare from '../components/objects/social/social-share/social-share';
import {
    hamburger,
    hamburgerClose
} from '../components/objects/nav/hamburger/hamburger';

import scrollToAnchor from './custom/scroll/scroll-to-anchor';
import parallaxScroll from './custom/parallax/parallaxscroll';
import headRoom from './custom/headroom/headroom';
import {navToggler} from '../components/objects/slide-outs/slide-out-basic/slide-out-basic';
import mapBlock from '../components/objects/map/map';
import showTestimonials from '../components/blocks/testimonials/testimonials';
import showTeam from '../components/blocks/leadership-team/leadership-team';
import showTabs from '../components/blocks/tabs-form/tabs-form';
import showInfoVideo from '../components/blocks/info-video/info-video';
import showAltInfoVideo from '../components/blocks/info-video-alternative/info-video-alternative';
import iframeResizer from "./custom/iframeResizer";
import showCertifiedPartners from '../components/blocks/certified-partners/certified-partners';
import showSingleBlog from './pages/single-blog';
import navigateCategory from '../components/objects/nav/nav-category/nav-category';
import calculateSavings from './pages/savings-calculator';
import showOverviewBlog from './pages/overview-blog';
import showStoryCards from '../components/blocks/story-cards/story-cards';

import './core/core';
/** Responsive Images Polyfill */
import 'picturefill';

// functions below are only run if the component exists (verified through a "data-module" in the html)
// in the ajax container or page. Barba.js instantiates the functions below on container reload as well.

// Place in alphabetical order
const app = {
    accordionTypeOne,
    accordionTypeTwo,
    calculateSavings,
    hamburger,
    headRoom,
    navigateCategory,
    searchBar,
    scrollToTop,
    socialShare,
    navToggler,
    mapBlock,
    showAltInfoVideo,
    showInfoVideo,
    showCertifiedPartners,
    showOverviewBlog,
    showSingleBlog,
    showStoryCards,
    showTeam,
    showTabs,
    showTestimonials,
    iframeResizer
};

let calledModulesNames = [];
app.instantiate = function (elem) {
    const $this = $(elem);
    const module = $this.attr('data-module');

    if (module === undefined) {
        throw 'Module not defined (use data-module="")';
    } else if (module in app) {
        if ($this.attr('data-initialized') === "true") return;
        new app[module](elem);
        $this.attr('data-initialized', true);
    } else {
        throw 'Module \'' + module + '\' not found';
    }
};

jQuery(document).ready(function () {
    scrollToAnchor($);
    navMob($);

    // Component functions that should only run if the component exists in the ajax container or page.
    $('[data-module]').each(function () {
        if ( $.inArray( $( this ).data( 'module' ), calledModulesNames ) == -1 ) {
            app.instantiate(this);

            calledModulesNames.push($(this).data('module'));
        }
    });

    $( '[data-fancybox^="heading-contact-form-modal"]' ).fancybox( {
        baseClass: "fancybox-heading-contact-form-modal",
        infobar: false,
        hash: false
    } );
});

jQuery(window).scroll(function () {
    parallaxScroll(jQuery);

    let scroll = $(window).scrollTop();

    if (scroll >= 100) {
        $(".l-header").addClass("l-headroom--not-top");
    } else {
        $(".l-header").removeClass("l-headroom--not-top");
    }
});
