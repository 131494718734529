import Swiper from 'swiper/dist/js/swiper.js';

function showTestimonials () {
    if ( $( '.c-testimonials__stories-slide-item ' ).length <= 1 ) {
        $( '.c-testimonials__stories-slider-wrapper' ).addClass( 'c-testimonials__stories-slider-wrapper--disabled' );

        $( '.c-testimonials__stories-slider-btn-prev' ).addClass( 'c-testimonials__stories-slider-btn-prev--disabled' );
        $( '.c-testimonials__stories-slider-btn-next' ).addClass( 'c-testimonials__stories-slider-btn-next--disabled' );

    } else {
        $( '.c-testimonials__stories-slider-wrapper' ).removeClass( 'c-testimonials__stories-slider-wrapper--disabled' );

        $( '.c-testimonials__stories-slider-btn-prev' ).removeClass( 'c-testimonials__stories-slider-btn-prev--disabled' );
        $( '.c-testimonials__stories-slider-btn-next' ).removeClass( 'c-testimonials__stories-slider-btn-next--disabled' );

        let swiperPartnersLogos = new Swiper( '.c-testimonials__stories-slider-container', {
            loop: true,
            speed: 600,
            autoplay: {
                delay: 7000,
            },
            slidesPerView: 1,
            spaceBetween: 48,
            navigation: {
                prevEl: '.c-testimonials__stories-slider-btn-prev',
                nextEl: '.c-testimonials__stories-slider-btn-next'
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
        } );
    }
}

export default showTestimonials;
