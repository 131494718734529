import '../../../js/vendors/fancybox/jquery.fancybox';

function showTeam () {
    $( '.c-leadership-team__item-wrapper' ).each( function () {
        let itemBorderColor = $( this ).css( 'border-top-color' );
        let itemModalDeco = $( this ).find( '.c-leadership-team__bio-modal-colored-deco' );

        itemModalDeco.css( 'background-color', itemBorderColor );
    } );

    $( '[data-fancybox="staff-member-biography"]' ).fancybox( {
        baseClass: "fancybox-staff-member-biography",
        infobar: false,
        hash: false,
        loop: true,
        btnTpl: {
            arrowLeft:
                '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
                '<div class="fancybox-arrow-icon-wrapper"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18 17" style="enable-background:new 0 0 18 17;" xml:space="preserve"><style type="text/css"> .st0{enable-background:new    ;}  .st1{fill:#FFFFFF;}</style><g id="Page-1-Copy"><g id="WHY-ABC---OPT-2" transform="translate(-1216.000000, -5739.000000)"><g id="QUOTE" transform="translate(-1.000000, 5424.000000)"><g id="Arrows" transform="translate(161.000000, 300.000000)"><g class="st0"><path class="st1" d="M1056.5,24.1c-0.2-0.2-0.2-0.4-0.2-0.6s0.1-0.4,0.2-0.6l7-7c0.2-0.2,0.4-0.2,0.6-0.2 c0.2,0,0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6l-5.6,5.6h13.8c0.2,0,0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6 c0,0.2-0.1,0.4-0.2,0.6s-0.4,0.2-0.6,0.2h-13.8l5.6,5.6c0.2,0.1,0.2,0.3,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6s-0.4,0.2-0.6,0.2 c-0.2,0-0.4-0.1-0.6-0.2L1056.5,24.1z"/></g></g></g></g></g></svg></div>' +
                "</button>",
            arrowRight:
                '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
                '<div class="fancybox-arrow-icon-wrapper"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18 17" style="enable-background:new 0 0 18 17;" xml:space="preserve"><style type="text/css"> .st0{enable-background:new    ;}  .st1{fill:#FFFFFF;}</style><g id="Page-1-Copy"><g id="WHY-ABC---OPT-2" transform="translate(-1216.000000, -5739.000000)"><g id="QUOTE" transform="translate(-1.000000, 5424.000000)"><g id="Arrows" transform="translate(161.000000, 300.000000)"><g class="st0"><path class="st1" d="M1066.5,31.1c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6 l5.6-5.6h-13.8c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6s0.4-0.2,0.6-0.2h13.8l-5.6-5.6 c-0.2-0.2-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6s0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2l7,7c0.2,0.2,0.2,0.4,0.2,0.6 s-0.1,0.4-0.2,0.6L1066.5,31.1z"/></g></g></g></g></g></svg></div>' +
                "</button>"
        }
    } );
}

export default showTeam;
