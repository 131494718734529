import '../../js/vendors/fancybox/jquery.fancybox';
$.fancybox.defaults.hash = false;

const savingsCalculatorCC = 'c-savings-calculator';
let chosenValuesObject = {};
let finalValuesObject = {};

function toggleSelectCustomIcon() {
    if ( $( `.${savingsCalculatorCC}__question-select-icon-wrapper` ).hasClass( `${savingsCalculatorCC}__question-select-icon-wrapper--open` ) ) {
        $( `.${savingsCalculatorCC}__question-select-icon-wrapper` ).toggleClass( `${savingsCalculatorCC}__question-select-icon-wrapper--open` );
    }
}

function clickActionOutsideArea( event, componentClass ) {
    if (
        $( event.target ).is( `.${componentClass}__question-select-wrapper` ) === false &&
        $( `.${componentClass}__question-select-wrapper` ).has( event.target ).length === 0
    )
    {
        toggleSelectCustomIcon();
    }
}

function formatNumberWithCommas( rangeNumber ) {
    return rangeNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function numbersRangeTooltipDynamicPositioning( dynamicNumber, slider ) {
    let rangeInput = slider;

    dynamicNumber.html( formatNumberWithCommas( rangeInput.val() ) );

    let rangeInputMin = rangeInput.attr( 'min' );
    let rangeInputMax = rangeInput.attr( 'max' );
    let rangeInputVal = rangeInput.val();
    let rangeInputThumbWidth = rangeInput.data( 'thumbWidth' );

    let tooltipPosition = ((rangeInputVal - rangeInputMin) / (rangeInputMax - rangeInputMin)) * 100;
    let tooltipPositionOffset = Math.round(rangeInputThumbWidth * tooltipPosition / 100) - (rangeInputThumbWidth / 2);

    dynamicNumber.css( 'left', `calc(${tooltipPosition}% - ${tooltipPositionOffset}px)` ).text( formatNumberWithCommas( rangeInputVal ) );
}

function calculateGenderCoefficient( dynamicNumber ) {
    let genderCoefficient = 1;

    switch( dynamicNumber ) {
        case 0:
            genderCoefficient = 0.96;
            break;
        case 10:
            genderCoefficient = 0.97;
            break;
        case 20:
            genderCoefficient = 0.98;
            break;
        case 30:
            genderCoefficient = 0.98;
            break;
        case 40:
            genderCoefficient = 0.99;
            break;
        case 50:
            genderCoefficient = 1;
            break;
        case 60:
            genderCoefficient = 1.01;
            break;
        case 70:
            genderCoefficient = 1.02;
            break;
        case 80:
            genderCoefficient = 1.02;
            break;
        case 90:
            genderCoefficient = 1.03;
            break;
        case 100:
            genderCoefficient = 1.04;
            break;
    }

    return genderCoefficient;
}

function calculateSavingsByZipCode( zipCode ) {
    let threeDigShortenZip = zipCode.substring( 0, 3 );
    let savingPercentage = 0.2;
    let shortenInNetworkZipCodes = [
        '079', '080', '081', '083', '085', '086', '088', '161', '163', '170', '173', '174', '175', '176', '180', '189',
        '190', '191', '192', '193', '194', '195', '197', '198', '327', '328', '330', '331', '332', '333', '334', '338',
        '347', '410', '450', '451', '452', '453', '459', '460', '461', '462', '469', '470', '473', '515', '600', '601',
        '602', '603', '604', '605', '606', '607', '608', '680', '681', '683', '684', '685', '688', '689', '750', '751',
        '752', '753', '754', '756', '757', '759', '760', '761', '762', '766', '770', '772', '773', '774', '775', '776',
        '777', '780', '781', '782', '785', '798', '799', '880', '885'
    ];

    switch( threeDigShortenZip ) {
        case '327':
            savingPercentage = 0.17;
            break;
        case '328':
            savingPercentage = 0.17;
            break;
        case '338':
            savingPercentage = 0.17;
            break;
        case '347':
            savingPercentage = 0.17;
            break;
        case '600':
            savingPercentage = 0.15;
            break;
        case '601':
            savingPercentage = 0.15;
            break;
        case '602':
            savingPercentage = 0.15;
            break;
        case '603':
            savingPercentage = 0.15;
            break;
        case '604':
            savingPercentage = 0.15;
            break;
        case '605':
            savingPercentage = 0.15;
            break;
        case '606':
            savingPercentage = 0.15;
            break;
        case '607':
            savingPercentage = 0.15;
            break;
        case '608':
            savingPercentage = 0.15;
            break;
        case '756':
            savingPercentage = 0.15;
            break;
        case '757':
            savingPercentage = 0.15;
            break;
        case '759':
            savingPercentage = 0.15;
            break;
        case '770':
            savingPercentage = 0.15;
            break;
        case '772':
            savingPercentage = 0.15;
            break;
        case '773':
            savingPercentage = 0.15;
            break;
        case '774':
            savingPercentage = 0.15;
            break;
        case '775':
            savingPercentage = 0.15;
            break;
        case '776':
            savingPercentage = 0.15;
            break;
        case '777':
            savingPercentage = 0.15;
            break;
        case '780':
            savingPercentage = 0.17;
            break;
        case '781':
            savingPercentage = 0.17;
            break;
        case '782':
            savingPercentage = 0.17;
            break;
        case '785':
            savingPercentage = 0.15;
            break;
        case '798':
            savingPercentage = 0.15;
            break;
        case '799':
            savingPercentage = 0.15;
            break;
        case '880':
            savingPercentage = 0.15;
            break;
        case '885':
            savingPercentage = 0.15;
            break;
    }

    return savingPercentage;
}

function getStateByZipCode( zipCode ) {
    let stateAbbreviation = '';
    let savingCoefficient = 1;

    zipCode = zipCode * 1;

    switch( true ) {
        case (zipCode >= 99501 && zipCode <= 99950):
            stateAbbreviation = 'AK';
            savingCoefficient = 1.2;
            break;
        case (zipCode >= 35004 && zipCode <= 36925):
            stateAbbreviation = 'AL';
            savingCoefficient = 0.8;
            break;
        case (zipCode >= 71601 && zipCode <= 72959):
            stateAbbreviation = 'AR';
            savingCoefficient = 0.93;
            break;
        case (zipCode >= 85001 && zipCode <= 86556):
            stateAbbreviation = 'AZ';
            savingCoefficient = 0.83;
            break;
        case (zipCode >= 90001 && zipCode <= 96162):
            stateAbbreviation = 'CA';
            savingCoefficient = 0.95;
            break;
        case (zipCode >= 80001 && zipCode <= 81658):
            stateAbbreviation = 'CO';
            savingCoefficient = 0.89;
            break;
        case (zipCode >= 6001 && zipCode <= 6389):
            stateAbbreviation = 'CT';
            savingCoefficient = 1.2;
            break;
        case (zipCode >= 6401 && zipCode <= 6928):
            stateAbbreviation = 'CT';
            savingCoefficient = 1.2;
            break;
        case (zipCode >= 19701 && zipCode <= 19980):
            stateAbbreviation = 'DE';
            savingCoefficient = 0.8;
            break;
        case (zipCode >= 32004 && zipCode <= 34997):
            stateAbbreviation = 'FL';
            savingCoefficient = 0.96;
            break;
        case (zipCode >= 30001 && zipCode <= 31999):
            stateAbbreviation = 'GA';
            savingCoefficient = 0.93;
            break;
        case (zipCode >= 39901 && zipCode <= 39901):
            stateAbbreviation = 'GA';
            savingCoefficient = 0.93;
            break;
        case (zipCode >= 96701 && zipCode <= 96898):
            stateAbbreviation = 'HI';
            savingCoefficient = 1.09;
            break;
        case (zipCode >= 50001 && zipCode <= 52809):
            stateAbbreviation = 'IA';
            savingCoefficient = 1.11;
            break;
        case (zipCode >= 68119 && zipCode <= 68120):
            stateAbbreviation = 'IA';
            savingCoefficient = 1.11;
            break;
        case (zipCode >= 83201 && zipCode <= 83876):
            stateAbbreviation = 'ID';
            savingCoefficient = 0.8;
            break;
        case (zipCode >= 60001 && zipCode <= 62999):
            stateAbbreviation = 'IL';
            savingCoefficient = 0.97;
            break;
        case (zipCode >= 46001 && zipCode <= 47997):
            stateAbbreviation = 'IN';
            savingCoefficient = 1.13;
            break;
        case (zipCode >= 66002 && zipCode <= 67954):
            stateAbbreviation = 'KS';
            savingCoefficient = 0.89;
            break;
        case (zipCode >= 40003 && zipCode <= 42788):
            stateAbbreviation = 'KY';
            savingCoefficient = 0.8;
            break;
        case (zipCode >= 70001 && zipCode <= 71232):
            stateAbbreviation = 'LA';
            savingCoefficient = 0.97;
            break;
        case (zipCode >= 71234 && zipCode <= 71497):
            stateAbbreviation = 'LA';
            savingCoefficient = 0.97;
            break;
        case (zipCode >= 1001 && zipCode <= 2791):
            stateAbbreviation = 'MA';
            savingCoefficient = 1.17;
            break;
        case (zipCode >= 5501 && zipCode <= 5544):
            stateAbbreviation = 'MA';
            savingCoefficient = 1.17;
            break;
        case (zipCode >= 20331 && zipCode <= 20331):
            stateAbbreviation = 'MD';
            savingCoefficient = 0.84;
            break;
        case (zipCode >= 20335 && zipCode <= 20797):
            stateAbbreviation = 'MD';
            savingCoefficient = 0.84;
            break;
        case (zipCode >= 20812 && zipCode <= 21930):
            stateAbbreviation = 'MD';
            savingCoefficient = 0.84;
            break;
        case (zipCode >= 3901 && zipCode <= 4992):
            stateAbbreviation = 'ME';
            savingCoefficient = 1.19;
            break;
        case (zipCode >= 48001 && zipCode <= 49971):
            stateAbbreviation = 'MI';
            savingCoefficient = 1.03;
            break;
        case (zipCode >= 55001 && zipCode <= 56763):
            stateAbbreviation = 'MN';
            savingCoefficient = 1.12;
            break;
        case (zipCode >= 38601 && zipCode <= 39776):
            stateAbbreviation = 'MS';
            savingCoefficient = 0.80;
            break;
        case (zipCode >= 71233 && zipCode <= 71233):
            stateAbbreviation = 'MS';
            savingCoefficient = 0.8;
            break;
        case (zipCode >= 63001 && zipCode <= 65899):
            stateAbbreviation = 'MO';
            savingCoefficient = 0.99;
            break;
        case (zipCode >= 59001 && zipCode <= 59937):
            stateAbbreviation = 'MT';
            savingCoefficient = 0.92;
            break;
        case (zipCode >= 27006 && zipCode <= 28909):
            stateAbbreviation = 'NC';
            savingCoefficient = 0.98;
            break;
        case (zipCode >= 58001 && zipCode <= 58856):
            stateAbbreviation = 'ND';
            savingCoefficient = 0.98;
            break;
        case (zipCode >= 68001 && zipCode <= 68118):
            stateAbbreviation = 'NE';
            savingCoefficient = 0.91;
            break;
        case (zipCode >= 68122 && zipCode <= 69367):
            stateAbbreviation = 'NE';
            savingCoefficient = 0.91;
            break;
        case (zipCode >= 3031 && zipCode <= 3897):
            stateAbbreviation = 'NH';
            savingCoefficient = 1.03;
            break;
        case (zipCode >= 7001 && zipCode <= 8989):
            stateAbbreviation = 'NJ';
            savingCoefficient = 1;
            break;
        case (zipCode >= 87001 && zipCode <= 88441):
            stateAbbreviation = 'NM';
            savingCoefficient = 0.8;
            break;
        case (zipCode >= 88901 && zipCode <= 89883):
            stateAbbreviation = 'NV';
            savingCoefficient = 0.8;
            break;
        case (zipCode >= 6390 && zipCode <= 6390):
            stateAbbreviation = 'NY';
            savingCoefficient = 0.94;
            break;
        case (zipCode >= 10001 && zipCode <= 14975):
            stateAbbreviation = 'NY';
            savingCoefficient = 0.94;
            break;
        case (zipCode >= 43001 && zipCode <= 45999):
            stateAbbreviation = 'OH';
            savingCoefficient = 1.09;
            break;
        case (zipCode >= 73001 && zipCode <= 73199):
            stateAbbreviation = 'OK';
            savingCoefficient = 1.03;
            break;
        case (zipCode >= 73401 && zipCode <= 74966):
            stateAbbreviation = 'OK';
            savingCoefficient = 1.03;
            break;
        case (zipCode >= 97001 && zipCode <= 97920):
            stateAbbreviation = 'OR';
            savingCoefficient = 1.01;
            break;
        case (zipCode >= 15001 && zipCode <= 19640):
            stateAbbreviation = 'PA';
            savingCoefficient = 0.9;
            break;
        case (zipCode >= 2801 && zipCode <= 2940):
            stateAbbreviation = 'RI';
            savingCoefficient = 1.17;
            break;
        case (zipCode >= 29001 && zipCode <= 29948):
            stateAbbreviation = 'SC';
            savingCoefficient = 1.2;
            break;
        case (zipCode >= 57001 && zipCode <= 57799):
            stateAbbreviation = 'SD';
            savingCoefficient = 1.07;
            break;
        case (zipCode >= 37010 && zipCode <= 38589):
            stateAbbreviation = 'TN';
            savingCoefficient = 0.8;
            break;
        case (zipCode >= 73301 && zipCode <= 73301):
            stateAbbreviation = 'TX';
            savingCoefficient = 0.84;
            break;
        case (zipCode >= 75001 && zipCode <= 75501):
            stateAbbreviation = 'TX';
            savingCoefficient = 0.84;
            break;
        case (zipCode >= 75503 && zipCode <= 79999):
            stateAbbreviation = 'TX';
            savingCoefficient = 0.84;
            break;
        case (zipCode >= 88510 && zipCode <= 88589):
            stateAbbreviation = 'TX';
            savingCoefficient = 0.84;
            break;
        case (zipCode >= 84001 && zipCode <= 84784):
            stateAbbreviation = 'UT';
            savingCoefficient = 0.80;
            break;
        case (zipCode >= 20040 && zipCode <= 20041):
            stateAbbreviation = 'VA';
            savingCoefficient = 0.98;
            break;
        case (zipCode >= 20040 && zipCode <= 20167):
            stateAbbreviation = 'VA';
            savingCoefficient = 0.98;
            break;
        case (zipCode >= 20042 && zipCode <= 20042):
            stateAbbreviation = 'VA';
            savingCoefficient = 0.98;
            break;
        case (zipCode >= 22001 && zipCode <= 24658):
            stateAbbreviation = 'VA';
            savingCoefficient = 0.98;
            break;
        case (zipCode >= 5001 && zipCode <= 5495):
            stateAbbreviation = 'VT';
            savingCoefficient = 1.2;
            break;
        case (zipCode >= 5601 && zipCode <= 5907):
            stateAbbreviation = 'VT';
            savingCoefficient = 1.2;
            break;
        case (zipCode >= 98001 && zipCode <= 99403):
            stateAbbreviation = 'WA';
            savingCoefficient = 0.98;
            break;
        case (zipCode >= 53001 && zipCode <= 54990):
            stateAbbreviation = 'WI';
            savingCoefficient = 1.14;
            break;
        case (zipCode >= 24701 && zipCode <= 26886):
            stateAbbreviation = 'WV';
            savingCoefficient = 0.89;
            break;
        case (zipCode > 82001 && zipCode < 83128):
            stateAbbreviation = 'WY';
            savingCoefficient = 0.8;
            break;
    }

    return {
        state_abbreviation: stateAbbreviation,
        saving_coefficient: savingCoefficient
    };
}

function calculateSavings () {
    $( `.${savingsCalculatorCC}__question-wrapper` ).on( 'click', `.${savingsCalculatorCC}__question-button`, function( e ) {
        e.preventDefault();

        let self = $( this );

        self.addClass( `${savingsCalculatorCC}__question-button--active` );

        $( 'html, body' ).animate({
            scrollTop: $( `.${savingsCalculatorCC}__progress-bar-title` ).offset().top - 80
        });

        const pageID = self.data( 'pageId' );
        let prevOrNextPageName = self.data( 'pageName' );
        let currentQuestionIndex = self.data( 'questionIndex' );
        let nextPrevQuestionIndex = self.data( 'nextprevIndex' );

        switch ( currentQuestionIndex ) {
            case 1:
                let selectedIndustryOption = $(`.${savingsCalculatorCC}__question-select option:selected`).val();

                chosenValuesObject.industry_option = {
                    value: selectedIndustryOption,
                    coefficient: selectedIndustryOption ? selectedIndustryOption * 1 : 1,
                    text: $(`.${savingsCalculatorCC}__question-select option:selected`).text(),
                };
                break;
            case 2:
                let zipCodeValue = $(`.${savingsCalculatorCC}__question-number-input-wrapper input`).val();
                let coefficientByStateObject = getStateByZipCode( zipCodeValue );
                let notAllowedStates = [ "AZ", "ND", "SD", "" ];

                if ( zipCodeValue == '' && nextPrevQuestionIndex == 3 ) {
                    if ( $(`.${savingsCalculatorCC}__question-number-input-wrapper` ).hasClass( `${savingsCalculatorCC}__question-number-input-wrapper--error-state` ) ) {
                        $(`.${savingsCalculatorCC}__question-number-input-wrapper` ).removeClass( `${savingsCalculatorCC}__question-number-input-wrapper--error-state` );
                    }

                    $(`.${savingsCalculatorCC}__question-number-input-wrapper` ).addClass( `${savingsCalculatorCC}__question-number-input-wrapper--error-required` );

                    self.removeClass( `${savingsCalculatorCC}__question-button--active` );

                    return;
                } else {
                    if ( $(`.${savingsCalculatorCC}__question-number-input-wrapper` ).hasClass( `${savingsCalculatorCC}__question-number-input-wrapper--error-required` ) ) {
                        $(`.${savingsCalculatorCC}__question-number-input-wrapper` ).removeClass( `${savingsCalculatorCC}__question-number-input-wrapper--error-required` );
                    }
                }

                if ( $.inArray( coefficientByStateObject.state_abbreviation, notAllowedStates ) != -1 && nextPrevQuestionIndex == 3 ) {
                    $(`.${savingsCalculatorCC}__question-number-input-wrapper` ).addClass( `${savingsCalculatorCC}__question-number-input-wrapper--error-state` );

                    self.removeClass( `${savingsCalculatorCC}__question-button--active` );

                    return;
                } else {
                    if ( $(`.${savingsCalculatorCC}__question-number-input-wrapper` ).hasClass( `${savingsCalculatorCC}__question-number-input-wrapper--error-state` ) ) {
                        $(`.${savingsCalculatorCC}__question-number-input-wrapper` ).removeClass( `${savingsCalculatorCC}__question-number-input-wrapper--error-state` );
                    }
                }

                chosenValuesObject.zip_code = {
                    value: zipCodeValue,
                    percentage: calculateSavingsByZipCode( zipCodeValue ),
                    coefficient_by_state: coefficientByStateObject.saving_coefficient
                };
                break;
            case 3:
                chosenValuesObject.employees_number = {
                    value: $(`.${savingsCalculatorCC}__numbers-range-slider`).val() * 1
                };
                break;
            case 4:
                if ( $( `.${savingsCalculatorCC}__percentage-parts-total-label input` ).val() != 100 && nextPrevQuestionIndex == 5 ) {
                    $( `.${savingsCalculatorCC}__percentage-parts-total-label` ).addClass( `${savingsCalculatorCC}__percentage-parts-total-label--error` );

                    self.removeClass( `${savingsCalculatorCC}__question-button--active` );

                    return;
                }

                let percentageInputsValues = [];
                let totalCalculation = 0;

                $( `.${savingsCalculatorCC}__percentage-part-label input` ).each( function () {
                    let inputValueNum = $( this ).val() * 1;
                    let inputCoefficient = $( this ).data( 'coefficient' ) * 1;

                    totalCalculation = inputValueNum * 0.01 * inputCoefficient + totalCalculation;

                    percentageInputsValues.push( inputValueNum );
                } );

                chosenValuesObject.employee_breakdown = {
                    value: totalCalculation * 1,
                    total: $( `.${savingsCalculatorCC}__percentage-parts-total-label input` ).val(),
                    items: percentageInputsValues
                };
                break;
            case 5:
                let genderBreakdownSlider = $( `.${savingsCalculatorCC}__gender-breakdown-slider` ).val();

                chosenValuesObject.gender_breakdown = {
                    value: calculateGenderCoefficient( genderBreakdownSlider * 1 ),
                    percentage: genderBreakdownSlider * 1
                };

                finalValuesObject = chosenValuesObject;
                break;
        }

        $.post(
            ajax_object.ajax_url,
            {
                action: 'change_savings_calculator_question',
                page_id: pageID,
                prev_next_page_name: prevOrNextPageName,
                final_values_object: finalValuesObject,
                next_prev_question_index: nextPrevQuestionIndex
            },
            function ( result ) {
                $( `.${savingsCalculatorCC}__question-wrapper` ).html( result );
            }
        ).done( function () {
            $( `.${savingsCalculatorCC}__progress-bar` ).removeClass( function ( index, css ) {
                return( css.match( /(^|\s)c-savings-calculator__progress-bar--step-\S+/g ) || []).join( ' ' );
            } ).addClass( `${savingsCalculatorCC}__progress-bar--step-${nextPrevQuestionIndex}` );

            self.removeClass( `${savingsCalculatorCC}__question-button--active` );

            switch ( nextPrevQuestionIndex ) {
                case 1:
                    if ( chosenValuesObject.hasOwnProperty( 'industry_option' ) ) {
                        $.each( $(`.${savingsCalculatorCC}__question-select option[value='${chosenValuesObject.industry_option.value}']`), function () {
                            if ( $( this ).text() == chosenValuesObject.industry_option.text ) {
                                $( this ).attr( 'selected', 'selected' );
                            }
                        } );
                    }
                    break;
                case 2:
                    if ( chosenValuesObject.hasOwnProperty( 'zip_code' ) ) {
                        $(`.${savingsCalculatorCC}__question-number-input-wrapper input`).val( chosenValuesObject.zip_code.value );
                    }
                    break;
                case 3:
                    let numbersRangeSlider = $( `.${savingsCalculatorCC}__numbers-range-slider` );
                    let rangeDynamicNumber = $( `.${savingsCalculatorCC}__numbers-range-slider-dynamic-count` );

                    if ( chosenValuesObject.hasOwnProperty( 'employees_number' ) ) {
                        numbersRangeSlider.val( chosenValuesObject.employees_number.value );

                        numbersRangeTooltipDynamicPositioning( rangeDynamicNumber, numbersRangeSlider );
                    }

                    rangeDynamicNumber.html( formatNumberWithCommas( numbersRangeSlider.val() ) );

                    numbersRangeSlider.on( 'change input', function() {
                        numbersRangeTooltipDynamicPositioning( rangeDynamicNumber, $( this ) );
                    } );
                    break;
                case 4:
                    if ( chosenValuesObject.hasOwnProperty( 'employee_breakdown' ) ) {
                        $( `.${savingsCalculatorCC}__percentage-part-label input` ).each( function ( index ) {
                            if ( chosenValuesObject.employee_breakdown.items[index] != "" ) {
                                $( this ).val( chosenValuesObject.employee_breakdown.items[index] );
                            } else {
                                $( this ).attr( "placeholder", 0 );
                            }
                        } );

                        $( `.${savingsCalculatorCC}__percentage-parts-total-label input`).val( chosenValuesObject.employee_breakdown.total );
                    }

                    $( `.${savingsCalculatorCC}__percentage-part-label input` ).on( 'input', function () {
                        let percentageTotal = 0;

                        $( `.${savingsCalculatorCC}__percentage-part-label input` ).each( function () {
                            let inputValueNum = $( this ).val() * 1;

                            percentageTotal = percentageTotal + inputValueNum;

                            $( `.${savingsCalculatorCC}__percentage-parts-total-label input` ).val( percentageTotal );
                        } );
                    } );
                    break;
                case 5:
                    let genderBreakdownSlider = $( `.${savingsCalculatorCC}__gender-breakdown-slider` );

                    if ( chosenValuesObject.hasOwnProperty( 'gender_breakdown' ) ) {
                        genderBreakdownSlider.val( chosenValuesObject.gender_breakdown.percentage );
                        let maleChosenPercentage = 100 - chosenValuesObject.gender_breakdown.percentage;

                        $( `.${savingsCalculatorCC}__gender-breakdown-percentage--female` ).html( `${chosenValuesObject.gender_breakdown.percentage}%` );
                        $( `.${savingsCalculatorCC}__gender-breakdown-percentage--male` ).html( `${maleChosenPercentage}%` );
                    }

                    genderBreakdownSlider.on( 'change input', function() {
                        let femalePercentage = $( this ).val() * 1;
                        let malePercentage = 100 - femalePercentage;

                        $( `.${savingsCalculatorCC}__gender-breakdown-percentage--female` ).html( `${femalePercentage}%` );
                        $( `.${savingsCalculatorCC}__gender-breakdown-percentage--male` ).html( `${malePercentage}%` );
                    } );
                    break;
                case 6:
                    let selectedIndustryText = "Not Provided";

                    if ( finalValuesObject.industry_option.value != "" ) {
                        selectedIndustryText = finalValuesObject.industry_option.text;
                    }

                    $( `.${savingsCalculatorCC}__additional-info-form #input_2_7` ).val( selectedIndustryText );

                    $( `.${savingsCalculatorCC}__additional-info-form #input_2_8` ).val( finalValuesObject.zip_code.value );

                    $( `.${savingsCalculatorCC}__additional-info-form #input_2_9` ).val( finalValuesObject.employees_number.value );

                    let employeeBreakdownSmaller30YO = `${finalValuesObject.employee_breakdown.items[0]}% | Younger than 30 years old`,
                        employeeBreakdown30YO = `${finalValuesObject.employee_breakdown.items[1]}% | 30-39 years old`,
                        employeeBreakdown40YO = `${finalValuesObject.employee_breakdown.items[2]}% | 40-49 years old`,
                        employeeBreakdown50YO = `${finalValuesObject.employee_breakdown.items[3]}% | 50-59 years old`,
                        employeeBreakdownMore60YO = `${finalValuesObject.employee_breakdown.items[4]}% | 60+ years old`;

                    $( `.${savingsCalculatorCC}__additional-info-form #input_2_10` ).val( `${employeeBreakdownSmaller30YO}, ${employeeBreakdown30YO}, ${employeeBreakdown40YO}, ${employeeBreakdown50YO}, ${employeeBreakdownMore60YO}` );

                    $( `.${savingsCalculatorCC}__additional-info-form #input_2_11` ).val( `${finalValuesObject.gender_breakdown.percentage}%` );
                    break;
            }
        } );
    } );

    $( `.${savingsCalculatorCC}__question-wrapper` ).on( 'click', `.${savingsCalculatorCC}__question-select-wrapper`, function() {
        let userAgentDetection = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        let customSelectIconWrapper = $( this ).children( `.${savingsCalculatorCC}__question-select-icon-wrapper` );

        if (
            userAgentDetection &&
            !customSelectIconWrapper.hasClass( `${savingsCalculatorCC}__question-select-icon-wrapper--open` )
        ) {
            customSelectIconWrapper.addClass( `${savingsCalculatorCC}__question-select-icon-wrapper--open` );
        }

        if ( !userAgentDetection ) {
            customSelectIconWrapper.toggleClass( `${savingsCalculatorCC}__question-select-icon-wrapper--open` );
        }
    } );

    $( `.${savingsCalculatorCC}__question-wrapper` ).on( 'change', `.${savingsCalculatorCC}__question-select`, function() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            $( this ).siblings( `.${savingsCalculatorCC}__question-select-icon-wrapper` ).toggleClass( `${savingsCalculatorCC}__question-select-icon-wrapper--open` );
        }
    } );

    $( document ).on( 'click', function( e ) {
        clickActionOutsideArea( e, savingsCalculatorCC );
    });

    $( document ).scroll( function () {
        toggleSelectCustomIcon();
    } );

    $( '[data-fancybox="savings-form-modal"]' ).fancybox( {
        baseClass: "fancybox-savings-form-modal",
        infobar: false,
        hash: false
    } );

    $( `.${savingsCalculatorCC}__question-wrapper` ).on( 'click', `.${savingsCalculatorCC}__contact-link`, function( e ) {
        if ( $( `.${savingsCalculatorCC}__additional-info-form #input_2_12` ).length != 0 ) {
            $( `.${savingsCalculatorCC}__additional-info-form #input_2_12` ).val( $( '.c-savings-calculator__result-page-total' ).text().trim() );
        }
    } );
}

export default calculateSavings;