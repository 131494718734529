var selector           = '.js-nav-toggler';
var selector2          = '.js-nav-toggler-label';
var openedClass        = 'c-slide-out__nav-toggler--opened';
var nav                = '.c-nav';
var navClass           = 'c-nav--opened';
var parent             = '.c-slide-out__column';
var icon               = '.c-slide-out__svg-shape-wrapper';
var iconOpenedClass    = 'c-slide-out__svg-shape-wrapper--opened';
var menuItem           = '.js-menu-item';
var activeMenuClass    = 'c-slide-out__nav-menu--active';
var isTouchDevice      = !!("undefined" != typeof document.documentElement.ontouchstart);

export function navToggler() {
    $(selector).click(function(e) {
        e.preventDefault();
        $(this).closest(parent).siblings().find(selector).removeClass(openedClass);
        $(this).toggleClass(openedClass);
        $(this).find(icon).toggleClass(iconOpenedClass);
        $(this).closest(parent).find(nav).slideToggle().toggleClass(navClass);
        $(this).closest(parent).siblings().find(nav).slideUp().removeClass(navClass);
    });

    // add for active on hover 
    $(menuItem).hover(function(){
        $(menuItem).removeClass(activeMenuClass);
        $(this).addClass(activeMenuClass);
    }, function(){
        $(menuItem).removeClass(activeMenuClass);
        // $(this).addClass(activeMenuClass);
    });
}