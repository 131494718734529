function showSingleBlog () {
    $( window ).on( 'scroll', function () {
        if ( $( window ).scrollTop()  + 70 > $( '.c-single-blog__social-share' ).offset().top ) {
            $( '.c-single-blog__social-share' ).addClass( 'c-single-blog__social-share--not-top' );
        } else {
            $( '.c-single-blog__social-share' ).removeClass( 'c-single-blog__social-share--not-top' );
        }
    } );
}

export default showSingleBlog;