import '../../../js/vendors/fancybox/jquery.fancybox';

function showInfoVideo () {
    $( '.c-info-video__btn' ).on( 'click', function ( event ) {
        let blockVersion = $( this ).parent().parent().data( "version" );

        if ( blockVersion != "image-only" ) {
            event.preventDefault ? event.preventDefault() : (event.returnValue = false);

            $( this ).closest( '.c-info-video__info-wrapper' ).siblings( '.c-info-video__media-wrapper' ).children( '.c-info-video__fancybox-link' ).trigger( 'click' );
        }
    } );

    $( '[data-fancybox-trigger="fancybox-info-video"]' ).fancybox( {
        baseClass: "fancybox-info-video",
        hash: false,
        arrows: false
    } );
}

export default showInfoVideo;