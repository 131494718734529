import '../../../js/vendors/fancybox/jquery.fancybox';

function showAltInfoVideo () {
    $( '.c-info-video-alternative__info-section-btn' ).on( 'click', function ( e ) {
        e.preventDefault();

        $( this ).closest( '.c-info-video-alternative__info-wrapper' ).siblings( '.c-info-video-alternative__media-wrapper' ).children( '.c-info-video-alternative__fancybox-link' ).trigger( 'click' );
    } );

    $( '[data-fancybox-trigger="fancybox-info-video"]' ).fancybox( {
        baseClass: "fancybox-info-video",
        hash: false,
        arrows: false
    } );
}

export default showAltInfoVideo;