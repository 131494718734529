/** Try to set cookie using URL GET parameter. */
var field_id = getValueFromURL('SFcampaign');
if (field_id) {
    setCookie('field_id', field_id);
}

const campain_id = field_id || getCookie('field_id');
$lazyloaded_iframes = $('iframe[data-src]');

$lazyloaded_iframes.each((i, element) => {
    let $element = $(element);
    var url = new URL($element.data('src'));
    var query_string = url.search;

    url += query_string ? '&SFcampaign=' + campain_id : '?SFcampaign=' + campain_id;
    
    $element.attr('src', url);
});

/**
 * Get value from URL parameter.
 *
 * @param {string} key
 */
function getValueFromURL(key) {
    var query = window.location.search.substring(1);
    var pairs = query.split('&');

    // Loop over parameters.
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        if (pair[0] === key) {
            return pair[1];
        }
    }

    return false;
}

/**
 * Set cookie
 * If days empty expire time will be session.
 *
 * @param {string} name
 * @param {string} value
 * @param {string} days cookie expire time
 */
function setCookie(name, value, days) {
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }

    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

/**
 * Get cookie via name.
 *
 * @param {string} name
 */
function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}
