/* eslint-disable */
let mixitup = require('mixitup');

function showCertifiedPartners () {
    let componentClass = 'c-certified-partners';
    let btnTitleGroup = $( `.${componentClass}__sorting-btn-group--title` );
    let btnDateGroup = $( `.${componentClass}__sorting-btn-group--date` );

    let mixer = mixitup(`.${componentClass}__items-wrapper`, {
        animation: {
            duration: 400
        }
    });

    $( `.${componentClass}__sorting-select` ).on( 'change', function () {
        let selectedOptionVal = $( this ).children( 'option:selected' ).val();

        if ( selectedOptionVal ) {
            if ( selectedOptionVal == 'title' ) {
                btnDateGroup.css( 'display', 'none' );

                btnTitleGroup.css( 'display', 'block' );
            }
            else if ( selectedOptionVal == 'date' ) {
                btnTitleGroup.css( 'display', 'none' );

                btnDateGroup.css( 'display', 'block' );
            }
        } else {
            btnTitleGroup.css( 'display', 'none' );
            btnDateGroup.css( 'display', 'none' );
        }
    } );
}

export default showCertifiedPartners;
