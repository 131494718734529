function showTabs () {
    $( '.c-tabs-form__tab-link' ).on( 'click', function () {
        let tabId = $( this ).attr( 'data-tab' );

        $( '.c-tabs-form__tab-link' ).removeClass( 'c-tabs-form__tab-link--current' );
        $( '.c-tabs-form__tab-content' ).removeClass( 'c-tabs-form__tab-content--current' );

        $( '.c-tabs-form__positions-group-arrow' ).removeClass( 'c-tabs-form__positions-group-arrow--active' );
        $( '.c-tabs-form__tabs' ).removeClass( 'c-tabs-form__tabs--open' );

        $( this ).addClass( 'c-tabs-form__tab-link--current' );
        $( `#${tabId}` ).addClass( 'c-tabs-form__tab-content--current' );

        $( '.c-tabs-form__positions-group-name' ).text( $(this).text() );
    });

    $( '.js-team-positions-group' ).click( function ( event ) {
        event.preventDefault();

        $( this ).find( '.c-tabs-form__positions-group-arrow' ).toggleClass( 'c-tabs-form__positions-group-arrow--active' );
        $( '.c-tabs-form__tabs' ).toggleClass( 'c-tabs-form__tabs--open' );
    });
}

export default showTabs;
