export default function iframeResizer() {
    $('[data-module=iframeResizer]').each((i, element) => {
        resizeIframe(element)
    });
}

function resizeIframe(element) {
    // Create browser compatible event handler.
    var eventMethod = window.addEventListener
        ? 'addEventListener'
        : 'attachEvent';
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';

    // Listen for a message from the iframe.
    eventer(
        messageEvent,
        function(e) {
            if (!e.data || isNaN(e.data)) return;
            element.style.height = e.data + 'px';
        },
        false
    );
}

