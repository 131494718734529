/*
 * Headroom
 *
 * Calling the Headroom.js object
 */
/* eslint-disable */
// let Headroom = require('headroom.js');

function headRoom() {
    // // Grab an element
    // let header = document.querySelector("#header");
    //
    // // Construct an instance of Headroom, passing the element
    // let headroom = new Headroom(header, {
    //     offset: 57,
    //     tolerance: 5,
    //     classes: {
    //         // when element is initialised
    //         initial: "l-headroom",
    //         // when scrolling up
    //         pinned: "l-headroom--pinned",
    //         // when scrolling down
    //         unpinned: "l-headroom--unpinned",
    //         // when above offset
    //         top: "l-headroom--top",
    //         // when below offset
    //         notTop: "l-headroom--not-top",
    //         // when at bottom of scoll area
    //         bottom: "l-headroom--bottom",
    //         // when not at bottom of scroll area
    //         notBottom: "l-headroom--not-bottom"
    //     }
    // });
    //
    // // Initialise
    // headroom.init();


    // $(window).scroll(function() {
    //     let scroll = $(window).scrollTop();
    //
    //     if (scroll >= 30) {
    //         $(".l-header").addClass("l-headroom--not-top");
    //     } else {
    //         $(".l-header").removeClass("l-headroom--not-top");
    //     }
    // });
}

export default headRoom;
